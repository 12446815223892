<template>
  <div>
    <div style="text-align: left;">
      <span style="font-size: small;">{{ $t("document.content") }}</span>
      <textarea
        v-model="agendaDescription"
        class="form-control mt-3"
        style="font-size: small;"
        :placeholder="$t('document.enterNewContent')"
        rows="2"
        v-bind:class="{
          'form-control': true,
          'is-invalid': !validRequire(agendaDescription) && fieldBlured,
        }"
        v-on:blur="fieldBlured = true"
      ></textarea>
      <div class="invalid-feedback" style="text-align: left;">
        {{ $t("document.fieldRequire.contentAgenda") }}
      </div>
    </div>
    <div class="mt-2 text-left">
      <span style="font-size: small;">{{ $t("document.documentUploader") }}</span>
      <VueMutiselect
        class="mt-3"
        :optionData="optionData"
        :customLabel="customLabel"
        :labelText="labelTextValue"
        :trackByText="trackByTextValue"
        :placeHolder="$t('mission.selectPerson')"
        :close-on-select="false"
        @select="getUploader"
      ></VueMutiselect>
    </div>
    <div class="row text-left mt-3">
      <div class="col-4">
        <span style="font-size: small;">{{ $t("document.endTime") }}</span>
      </div>
      <div class="col-6 text-right">
        <date-picker
          style="font-size: small; text-align: left;"
          v-model="endDate"
          :config="endDateOptions"
        ></date-picker>
      </div>
    </div>
    <div class="mt-3 text-left">
      <button
        type="submit"
        class="btn submit-button"
        style="font-size: small;"
        @click="checkAgendaForm"
      >
        {{ $t("document.addContent") }}
      </button>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import dateUtils from "utils/dateUtils";
import VueMutiselect from "Components/VueMutiselect";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import functionUtils from 'utils/functionUtils'
import Enum from "enum/enum"
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    VueMutiselect,
  },
  data: () => ({
    optionData: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    agendaDescription: "",
    endDate: new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear(),
    endDateOptions: {
      format: "DD/MM/YYYY",
      useCurrent: false,
      locale: "vi",
    },
    fieldBlured: false,
    eventId: null,
    eventSessionDocumentUploader: [],
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR", "GET_USER_IN_EVENT_LIST_DATA"]),
  },
  watch: {
    GET_USER_IN_EVENT_LIST_DATA() {
      this.optionData = [];
      if (!functionUtils.isNull(this.GET_USER_IN_EVENT_LIST_DATA)) {
        this.GET_USER_IN_EVENT_LIST_DATA.forEach((data) => {
          let full_name = data.lastname + " " + data.firstname;
          let item = {
            id: data.id,
            full_name: full_name,
            url_avatar:
              data.url_avatar != null || data.url_avatar != ""
                ? data.url_avatar
                : null,
            is_group: data.is_group
          };
          this.optionData.push(item);
        });
      }
    },
  },
  methods: {
    /**
     * Get uploader
     */
    getUploader(data) {
      this.eventSessionDocumentUploader = [];
      data.forEach((item) => {
        this.eventSessionDocumentUploader.push({id: item.id, is_group: item.is_group});
      });
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    /**
     * On create agenda in event
     */
    onCreateAgendaInEvent: function() {
      // Initial current date and format to compare
      let endDateTime = dateUtils.formatDate(
        this.endDate,
        dateUtils.STATIC_FORMAT_DATE,
        dateUtils.STATIC_FORMAT_DATE_SQL
      );
      let currentDateTime = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL);
      if (dateUtils.compareDate(endDateTime, currentDateTime) == -1) {
        this.ON_SHOW_TOAST({
          message: this.$t("compareDate.startNotSmallerCurrentTime"),
          styleType: "danger",
        });
      } else {
        let filter = {
          start_datetime: dateUtils.formatDate(
            currentDateTime,
            dateUtils.STATIC_FORMAT_DATE_SQL,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T
          ),
          end_datetime: dateUtils.formatDate(
            endDateTime,
            dateUtils.STATIC_FORMAT_DATE_SQL,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T
          ),
          description: this.agendaDescription,
          eventId: this.eventId,
          event_session_document_uploaders: this.eventSessionDocumentUploader,
        };
        this.CREATE_AGENDA_IN_EVENT(filter)
          .then(
            function(res) {
              let data = res.data;
              this.agendaDescription = null;
              this.startDate = null;
              this.endDate = null;
              this.$emit("addEventSessionSuccess");
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success",
              });
              this.sendNotification(data.session_id)
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    /**
     * Check form
     */
    checkAgendaForm: function() {
      this.fieldBlured = true;
      if (this.validRequire(this.agendaDescription)) {
        this.onCreateAgendaInEvent();
      }
    },
    /**
     * Valid require
     */
    validRequire: function(fieldRequire) {
      if (fieldRequire != "") {
        return true;
      } else {
        return false;
      }
    },
    sendNotification(sessionId){
      let userList = []
      let groupList = []

      this.eventSessionDocumentUploader.forEach(data=>{
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let notificationData = {
        event_id: this.eventId,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.UPDATE_USER_UPLOAD_DOCUMENT,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.DocumentUploader,
        sessionId: sessionId
      }
      if(this.eventSessionDocumentUploader.length > 0){
        this.SEND_NOTIFICATION_IN_MEETING(notificationData)
      }

      let smsData = {
        event_id: this.eventId,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.UPDATE_USER_UPLOAD_DOCUMENT,
        template_type: Enum.TemplateType.SMS,
        sessionId: sessionId
      }
      if(this.eventSessionDocumentUploader.length > 0){
        this.SEND_SMS_IN_MEETING(smsData)
      }
    },
    ...mapActions([
      "CREATE_AGENDA_IN_EVENT",
      "ON_SHOW_TOAST",
      "GET_USER_IN_EVENT_LIST",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING"
    ]),
  },
  mounted() {
    this.eventId = sessionStorage.getItem("event_id");
    let filter = {
      params: {
        event: this.eventId,
        bandGroup: true
      },
    };
    this.GET_USER_IN_EVENT_LIST(filter);
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  color: #ffffff;
}
</style>
