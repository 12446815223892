<template>
  <div class="container-fluid">
    <div class="row">
      <div v-show="!isZoom" class="col-sm-4 p-0" style="border-right: 1px solid #e5e5e5;">
        <avatar-tabs
          ref="avatarTab"
          :type="tabType"
          :titleList="titles"
          @eventSelect="onEventSelected"
          @document="onSelectDocument"
          @addEventSession="onAddEventSession"
          @addDocumentInEventSession="onAddDocumentInEventSession"
          @closeNote="onCloseNote"
          @personalTabSelect="onPersonalTabSelect"
          @selectDocumentPersonal="onSelectDocumentPersonal"
          @documentExchangeTab="onDocumentExchangeSelectTab"
          @onSelectDocumentExchangeData="onSelectDocumentExchangeData"
        ></avatar-tabs>
      </div>
      <div
        style="position: relative;"
        :class="isZoom ?'col-sm-12 mt-2' : 'col-sm-8 mt-2'"
      >
        <!-- document general -->
        <div style="display: flex;" v-if="documentPersonalTabSelect || isShowResourcesInEventSession">
          <div style="width: 75%;">
            <p
              class="pre-text"
              v-if="!addEventSession && !addEventSessionDocument && !documentPersonalTabSelect && !showDocumentExchange"
              style="text-align: left; color: #7D7D7D; display: inline-block; float: left;"
            >
              {{
                isShowResourcesInEventSession
                  ? eventSessionDescriptionSelected
                  : documentSelect.name
              }}
            </p>
            <p class="pre-text" style="text-align: left; color: #7D7D7D; display: inline-block; float: left;" v-else-if="documentPersonalTabSelect">
              {{ documentPersonalName }}
            </p>
            <!--  -->
            <div class="text-left mb-2"
              v-if="!addEventSession && !addEventSessionDocument && !documentPersonalTabSelect
                && (isChairmanRole() || isSecretaryRole())
              "
            >
              <button
                type="submit"
                class="btn submit-button ml-2"
                style="font-size: small;"
                @click="onShowDocumentUploaderModal()"
              >
                {{ $t('document.updateDocumentUploader') }}
              </button>
            </div>
            <!--  -->
          </div>
          <div style="width: 25%;">
            <div style="display: flex; float: right; align-self: center;">
              <!-- Expand -->
              <div
                v-if="
                  !isShowResourcesInEventSession &&
                    !addEventSession &&
                    !addEventSessionDocument
                "
                style="cursor: pointer;"
                @click="onExpand()"
                class="mr-3"
              >
                <div v-show="!isZoom">
                  <i class="fas fa-expand expand"></i>
                </div>
                <div v-show="isZoom">
                  <i class="fas fa-compress expand"></i>
                </div>
              </div>
              <!-- Comment note -->
              <div
                v-if="
                  !isShowResourcesInEventSession &&
                  !addEventSession &&
                  !addEventSessionDocument && 
                  (isChairmanRole() || isSecretaryRole() || documentSelect.status == documentStatus.APPROVED)
                "
                style="cursor: pointer;"
                @click="onOpenNote()"
              >
                <img src="../../assets/icons/document.svg" width="15px;">
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both;"></div>
        <!-- Document item -->
        <!-- document personal -->
        <div v-if="documentPersonalTabSelect" class="pdf">
          <div v-if="documentPersonalSelected.status == documentStatus.PERSONAL_NOTE" class="text-left mb-3">
            <button
              type="submit"
              class="btn submit-button"
              style="font-size: small;"
              @click="onSwitchToGeneralDocument"
            >
              {{ $t('document.btnPersonalToGeneral') }}
            </button>
            <button
              type="submit"
              class="btn submit-button ml-2"
              style="font-size: small;"
              @click="onSwitchToEditPdfMode(true)"
            >
              {{ $t('document.documentComment') }}
            </button>
          </div>
          <div class="pdf_file" style="position: relative;">
            <div v-if="!editPdfMode">
              <iframe
                v-if="!isNull(documentPersonalUrl) && !isEmptyString(documentPersonalUrl)"
                :src="documentPersonalUrl + '#view=FitH'"
                style="width: 100%; height:auto; min-height: 640px; overflow-x: hidden;"
                frameborder="0"
              ></iframe>
            </div>
            <pdfAnnotate
              v-else
              :src="documentPersonalUrl ? documentPersonalUrl : ''"
              :toolbar="documentPersonalUrl && true"
              :commentWrapper="false"
            ></pdfAnnotate>
            <div v-if="documentPersonalUrl && editPdfMode" class="save-document-personal-btn">
              <button class="meeting-font-10px btn submit-button" @click="saveDocumentPersonal()">{{ $t('common.save') }}</button>
            </div>
            <div v-if="editPdfMode" class="close-edit-pdf-mode-btn">
              <button class="meeting-font-10px btn submit-button" @click="onSwitchToEditPdfMode(false)">{{ $t('common.close') }}</button>
            </div>
          </div>
        </div>
        <!-- resources event session -->
        <div
          v-else-if="isShowResourcesInEventSession"
          class="document"
          style="text-align: left; display: block;"
        >
          <div v-if="documents.length > 0">
            <div v-for="(document, index) in documents" :key="document + index">
              <div style="cursor: pointer !important; margin-left: 15px;">
                <div
                  :class="index != 0 ? 'mt-2' : ''"
                  @click="onSelectDocument(document)"
                >
                  <img
                    class="mr-2"
                    src="../../assets/icons/file-pdf.svg"
                    style="width: 16px; height: 35px;"
                  />
                  <span class="pre-text" style="font-weight: bold;">{{ document.name }}</span>
                  <div style="display: inline-block;">
                    <div v-if="!document.isSelected">
                      <i class="fas fa-chevron-right ml-3"></i>
                    </div>
                    <div v-else style="display: inline-block;">
                      <i class="fas fa-chevron-down ml-3"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-5 mt-2">
                <!-- Comment item -->
                <div v-if="document.isSelected">
                  <div
                    v-for="(note, index) in document.notes"
                    :key="note + index"
                  >
                    <div style="m-2">
                      <span style="font-weight: bold; font-size: small;">{{
                        note.user_created.last_name +
                          " " +
                          note.user_created.first_name
                      }}</span>
                      |
                      <span style="font-style: italic; font-size: small;">{{
                        formatDateTime(note.date_created)
                      }}</span>
                      <p style="font-size: small;">
                        {{ note.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="font-sm-italic" style="margin-left: 0 !important;">
              {{ $t("scheduleMeeting.noDocument") }}
            </span>
          </div>
        </div>
        <!-- Add event session -->
        <div v-else-if="addEventSession" style="width: 50%;">
          <add-event-session @addEventSessionSuccess="onAddEventSessionSuccess"></add-event-session>
        </div>
        <!-- document info -->
        <div v-else-if="!addEventSessionDocument && !showDocumentExchange" class="document" style="text-align: left; display: block;">
          <div v-if="documentSelect && !isNull(documentSelect.name)">
            <div style="display: flex;">
              <!-- Manipulation for owner document -->
              <div
                v-if="GET_CURRENT_ACCOUNT.id == documentSelect.ownerId"
                class="mb-3"
                style="display: inline-block;"
              >
                <button
                  v-if="documentSelect.status != documentStatus.APPROVED"
                  type="submit"
                  class="btn submit-button"
                  style="font-size: small;"
                  @click="onUpdateDocument()"
                >
                  {{ $t("userProfile.update") }}
                </button>
                <button
                  @click="onShowModalDeleteDocument()"
                  type="submit"
                  :class="['btn', documentSelect.status != documentStatus.APPROVED ? 'ml-3' : '']"
                  style="font-size: small; border: 1px solid #F2404D; color: #F2404D;"
                >
                  {{ $t("common.delete") }}
                </button>
              </div>
              <!-- Approve -->
              <div
                v-if="(isChairmanRole() || !this.isNull(this.documentSelect.currentDigitalSigner)) && documentSelect.status == documentStatus.AWAITING_APPROVAL"
                class="ml-3 mb-3"
                style="display: inline-block;"
              >
                <button
                  type="submit"
                  class="btn submit-button"
                  style="font-size: small; width: 146.87px;"
                  @click="approveDocument()"
                >
                  {{ $t("document.role.approver") }}
                </button>
                <button
                  @click="showModalRejectDocument()"
                  type="submit"
                  class="btn ml-3"
                  style="font-size: small; border: 1px solid #F2404D; color: #F2404D; width: 146.87px;"
                >
                  {{ $t("document.reject") }}
                </button>
              </div>
              <!-- Update document reviewer -->
              <div
                v-if="(isSecretaryRole() || isChairmanRole()) && documentSelect.status == documentStatus.APPROVED"
                class="mb-3 ml-3"
                style="display: inline-block;"
              >
                <button
                  type="submit"
                  class="btn submit-button"
                  style="font-size: small;"
                  @click="updateDocumentReviewer()"
                >
                  {{ $t('document.updateDocumentReviewer') }}
                </button>
              </div>
              <!-- Update document approver -->
              <div
                v-if="documentSelect.status == documentStatus.SUMMARY && (isSecretaryRole() || isChairmanRole())"
                class="mb-3 ml-3"
                style="display: inline-block;"
              >
                <button
                  type="submit"
                  class="btn submit-button"
                  style="font-size: small;"
                  @click="updateDocumentApprover()"
                >
                  {{ $t('document.updateDigitalSigner') }}
                </button>
              </div>
              <!-- Signature document file -->
              <div
                v-if="isHasPermSignature()"
                class="mb-3 ml-3"
              >
                <button
                    type="submit"
                    class="btn submit-button"
                    style="font-size: small;"
                    @click="exc_sign_file()"
                  >
                    {{ $t("document.signTheNumber") }}
                  </button>
              </div>
              <div class="ml-3">
                <button
                  type="submit"
                  class="btn submit-button"
                  style="font-size: small;"
                  @click="onSwitchToEditPdfMode(true)"
                >
                  {{ $t('document.documentComment') }}
                </button>
              </div>
            </div>
            <!-- Pdf view -->
            <div v-if="checkPermissionViewDocument()" class="pdf">
              <div class="pdf_file" style="position: relative;">
                <div v-if="!editPdfMode">
                  <iframe
                    v-if="!isNull(documentSelect.urlPdfAnnotate) && !isEmptyString(documentSelect.urlPdfAnnotate)"
                    :src="documentSelect.urlPdfAnnotate + '#view=FitH'"
                    style="width: 100%; height:auto; min-height: 640px; overflow-x: hidden;"
                    frameborder="0"
                  ></iframe>
                </div>
                <pdfAnnotate
                  v-else
                  :src="documentSelect.urlPdfAnnotate ? documentSelect.urlPdfAnnotate : ''"
                  :toolbar="true"
                  :commentWrapper="false"
                ></pdfAnnotate>
                <div v-if="editPdfMode" class="save-document-personal-btn">
                  <button class="meeting-font-10px btn submit-button" @click="saveDocumentPersonal()">{{ $t('common.save') }}</button>
                </div>
                <div v-if="editPdfMode" class="close-edit-pdf-mode-btn">
                  <button class="meeting-font-10px btn submit-button" @click="onSwitchToEditPdfMode(false)">
                    {{ $t('common.close') }}
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="font-sm-italic">{{
                $t("scheduleMeeting.noDocument")
              }}</span>
            </div>
          </div>
          <div v-else>
            <span class="font-sm-italic">{{
              $t("scheduleMeeting.noDocument")
            }}</span>
          </div>
        </div>
        <!-- Add document in event session -->
        <div
          v-show="addEventSessionDocument && !showDocumentExchange"
          style="width: 70%; text-align: left;"
        >
          <add-document-in-event-session
            ref="addDocumentInEventSession"
            @addDocumentSuccess="addDocumentInEventSuccess"
          ></add-document-in-event-session>
        </div>
        <!--  -->
        <div
          v-show="!addEventSessionDocument && showDocumentExchange">
          <documentExchange
            ref="documentExchange"
            :documentData="documentExchangeData"
            @onZoomDocumentExchange="onZoomDocumentExchange"
          />
        </div>
        <!-- Note container -->
        <transition name="slide-fade">
          <div
            v-if="openNote"
            style="position: fixed; top: 15%; right: 0; width: 350px; height: 350px;"
          >
            <div>
              <div style="text-align: left; cursor: pointer; background-color: #fff; padding: 10px" @click="openNote = false">
                <i class="fas fa-times" style="color: red; font-size: large;"></i>
                <span class="ml-2">{{ $t('document.note.title') }}</span>
              </div>
              <noteDocument :documentId="documentSelect.id" :eventId="Number(eventId)"></noteDocument>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <b-modal
      :title="$t('document.documentNotApproved')"
      centered
      id="modal-confirm-absence"
      ref="modal-confirm-absence"
    >
      <div class="container">
        <div class="row p-2">
          <span class="mb-2" style="font-size: small;">{{
            $t("document.messageForDocumentCreator")
          }}</span>
          <textarea
            v-model="reasonReject"
            class="form-control p-2"
            cols="45"
            rows="5"
            :placeholder="$t('document.reasonNotApproved')"
          />
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 pl-2 pr-2">
          <button
            class="btn submit-button float-right"
            @click="rejectDocument()"
          >
            {{ $t("common.send") }}
          </button>
          <button
            class="btn float-right mr-2"
            @click="$bvModal.hide('modal-confirm-absence')"
          >
            {{ $t("common.close") }}
          </button>
        </div>
      </template>
    </b-modal>
    <reviewerModal ref="reviewerModal" @approveSuccess="addDocumentInEventSuccess"></reviewerModal>
    <summaryAssignSigner ref="summaryAssignSigner"></summaryAssignSigner>
    <confirmModal ref="confirmModal" @onConfirm="onConfirmDeleteDocument"></confirmModal>
    <switchToGeneralDocument ref="switchToGeneralDocument" @switchDocumentSuccess="onSwitchDocumentSuccess"></switchToGeneralDocument>
    <documentUploaderModal ref="documentUploaderModal"></documentUploaderModal>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import AvatarTabs from "Components/AvatarTabs";
import TabType from "enum/tabType";
import { mapActions, mapGetters } from "vuex";
import dateUtils from "utils/dateUtils";
import AddDocumentInEventSession from "./AddDocumentInEventSession";
import AddEventSession from './AddEventSession'
import DocumentApproverStatus from "enum/documentApproverStatus";
import DocumentStatus from 'enum/documentStatus'
import functionUtils, { uploadFileToMediaAgent } from 'utils/functionUtils';
import ReviewerModal from 'Components/AssignReviewerModal'
import NoteDocument from './NoteDocument'
import documentExchange from './DocumentExchange'
import ConfirmModal from 'Components/ConfirmModal'
import pdfAnnotate from "pdf-annotate-vue";
import "pdf-annotate-vue/src/css/toolbar.css";
import "pdf-annotate-vue/src/css/pdf_viewer.css";
import html2pdf from 'html2pdf.js'
import SummaryAssignSigner from 'Views/Summary/SummaryAssignSigner'
import AddDocumentType from 'enum/addDocumentType'
import SwitchToGeneralDocument from './SwitchToGeneralDocuments'
import DocumentUploaderModal from 'Components/DocumentUploaderModal'
import Enum from 'enum/enum'
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    confirmModal: ConfirmModal,
    pdfAnnotate,
    avatarTabs: AvatarTabs,
    addDocumentInEventSession: AddDocumentInEventSession,
    addEventSession: AddEventSession,
    reviewerModal: ReviewerModal,
    noteDocument: NoteDocument,
    summaryAssignSigner: SummaryAssignSigner,
    switchToGeneralDocument: SwitchToGeneralDocument,
    documentUploaderModal: DocumentUploaderModal,
    documentExchange
  },
  data: () => ({
    tabType: TabType.MEETING_RESOURCE,
    titles: ["document.generalDocuments", "document.personal"],
    documentSelected: null,
    eventSessionDescriptionSelected: null,
    eventSessionSpeakerSelected: null,
    isShowResourcesInEventSession: true,
    documents: [],
    eventSessionId: 0,
    isZoom: false,
    openNote: false,
    documentSelect: {
      id: "",
      name: "",
      url: "",
      ownerId: null,
      status: null,
      reviewer: null,
      hasViewCurrentDocument: false,
      urlPdfAnnotate: "",
      currentDigitalSigner: null
    },
    noteDescription: "",
    addEventSession: false,
    addEventSessionDocument: false,
    reasonReject: "",
    eventId: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    documentStatus: {
      AWAITING_APPROVAL: DocumentStatus.AWAITING_APPROVAL,
      COMPLETE: DocumentStatus.COMPLETE,
      APPROVED: DocumentStatus.APPROVED,
      REJECTED: DocumentStatus.REJECTED,
      SUMMARY: DocumentStatus.SUMMARY,
      PERSONAL_UPLOAD: DocumentStatus.PERSONAL_UPLOAD,
      PERSONAL_NOTE: DocumentStatus.PERSONAL
    },
    documentApproverStatus: {
      IN_REVIEW: DocumentApproverStatus.IN_REVIEW,
      UNAPPROVED: DocumentApproverStatus.UNAPPROVED,
      APPROVED: DocumentApproverStatus.APPROVED
    },
    documentPersonalTabSelect: false,
    documentPersonalUrl: null,
    documentPersonalName: null,
    documentPersonalSelected: {
      status: null,
      id: null
    },
    event:{
      name:''
    },
    currentUser: {
      id: 0,
      lastName: '',
      firstName: ''
    },
    editPdfMode: false,
    showDocumentExchange: false,
    documentExchangeData: null
  }),
  computed: {
    ...mapGetters([
      "GET_DOCUMENTS_IN_EVENT_SESSION_DATA",
      "GET_SNACK_BAR",
      "GET_CURRENT_ACCOUNT",
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "EVENT_DETAIL_DATA"
    ]),
  },
  watch: {
    GET_DOCUMENTS_IN_EVENT_SESSION_DATA: function() {
      this.documents = [];
      let documents = this.GET_DOCUMENTS_IN_EVENT_SESSION_DATA;
      for (let j = 0, documentSize = documents.length; j < documentSize; j++) {
        if (documents[j] == null) {
          documents.splice(j, 1)
        }
      }
      for (let i = 0, size = documents.length; i < size; i++) {
        let documentObj = {
          id: documents[i].id,
          name: documents[i].name,
          url: documents[i].url,
          isShowNote: false,
          isSelected: false,
          notes: documents[i].notes,
          description: "",
          posterUrl: documents[i].poster_url
            ? documents[i].poster_url
            : "https://event.avatarnext.com/static/images/noposter.png",
          currentDigitalSigner: documents[i].current_digital_signer,
          owner: documents[i].owner,
          reviewer: documents[i].reviewer,
          hasViewCurrentDocument: documents[i].has_view_current_document,
          status: documents[i].status
        };
        this.documents.push(documentObj);
      }
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    EVENT_DETAIL_DATA: function(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  created() {
    this.GET_TOKEN_MEDIA_SERVER()
    this.eventId = sessionStorage.getItem('event_id')
    let event = {
      params:{
        is_meeting: true
      },
      id: event
    }
    this.GET_EVENT_DETAIL(event)
  },
  methods: {
    /**
     * Switch to general document
     */
    onSwitchToGeneralDocument: function () {
      this.$refs.switchToGeneralDocument.onShowModal(this.documentPersonalSelected.id, this.documentPersonalName)
    },
    /**
     * Check has perm signature
     */
    isHasPermSignature: function () {
      if (!this.isNull(this.documentSelect.url) && !this.isShowResourcesInEventSession && !this.addEventSession && !this.addEventSessionDocument && (this.isSecretaryRole() || this.isChairmanRole() || this.GET_CURRENT_ACCOUNT.id == this.documentSelect.ownerId || !this.isNull(this.documentSelect.currentDigitalSigner))) {
        return true
      } else {
        return false
      } 
    },
    /**
     * Update document approver
     */
    updateDocumentApprover: function () {
      /**
       * Param 1: Document file
       * Param 2: Document file name
       * Param 3: Variable is update
       * Param 4: Current document selected
       */
      this.$refs.summaryAssignSigner.onShowModalConfirm(null, null, true, this.documentSelect)
    },
    /**
     * Select document personal
     */
    onSelectDocumentPersonal: function (document) {
      this.documentPersonalTabSelect = true
      this.documentPersonalUrl = document.url && document.url.replace('app.avatarnext.com', 'meeting.avatarnext.com')
      this.documentPersonalName = document.name
      this.documentPersonalSelected.status = document.status
      this.documentPersonalSelected.id = document.id
    },
    /**
     * Peronal tab select
     */
    onPersonalTabSelect: function () {
      this.documentPersonalTabSelect = true
      this.showDocumentExchange = false
    },
    /**
     * Save document personal
     */
    saveDocumentPersonal: async function () {
      var element = document.getElementById('viewer');
      var opt = {
        margin: 0,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };
      html2pdf().set(opt).from(element).toPdf().output('datauristring').then((pdf) => {
        let arr = pdf.split(',')
        let base64 = arr[1]
        let file = functionUtils.b64toBlob(base64, 'application/pdf')
        this.uploadPDFFile(file)
      })
    },
    uploadPDFFile: async function (filename) {
      // Change aias and replace space to '_' character
      let documentNameChangeAlias = functionUtils.changeAlias(this.documentSelect.name)
      documentNameChangeAlias = functionUtils.removeSpecialCharacter(documentNameChangeAlias)
      documentNameChangeAlias = documentNameChangeAlias.split(' ').join('_');
      documentNameChangeAlias = documentNameChangeAlias + '.pdf'

      let url = await uploadFileToMediaAgent(filename, "personal/documents/" + this.GET_CURRENT_ACCOUNT.id, documentNameChangeAlias)
      if (url != null) {
        let filter = {
          name: this.documentSelect.name,
          url: url,
          event: this.eventId,
          event_session: null,
          document_type: null,
          status: this.documentStatus.PERSONAL_NOTE,
          document_poster: null,
          speaker: null,
          message: null,
          save_document_type: 0,
          security: true
        }
        this.CREATE_DOCUMENT(filter).then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success"
            });
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger"
            });
          }.bind(this)
        )
      }
    },
    /**
     * Confirm delete document
     */
    onConfirmDeleteDocument: function () {
      this.DELETE_DOCUMENT({ id: this.documentSelect.id }).then(
        function() {
          this.$refs.avatarTab.addDocumentSuccess();
          this.ON_SHOW_TOAST({
            message: this.$t("common.success"),
            styleType: "success",
          });
        }.bind(this)
      );
    },
    /**
     * Close note
     */
    onCloseNote: function () {
      this.openNote = false
    },
    /**
     * Check permission view document
     */
    checkPermissionViewDocument: function () {
      if (this.isNull(this.documentSelect.url) || this.isEmptyString(this.documentSelect.url)) {
        return false
      } else if (
        (!this.isNull(this.documentSelect.reviewer) || this.documentSelect.hasViewCurrentDocument) && 
        (this.documentSelect.status == this.documentStatus.COMPLETE || 
        this.documentSelect.status == this.documentStatus.APPROVED ||
        this.documentSelect.status == this.documentStatus.SUMMARY)
      ) {
        return true
      } else if (this.GET_CURRENT_ACCOUNT.id == this.documentSelect.ownerId || this.isChairmanRole()
        || this.documentSelect.currentDigitalSigner != null
      ) {
        return true
      } else {
        return false
      }
    },
    /**
     * Check has support role
     */
    isSupportRole: function () {
      return functionUtils.isSupportRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check has support role
     */
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check has chairman role
     */
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Sign file callback
     */
    SignFileCallBack(rv) {
      var received_msg = JSON.parse(rv);
      if (received_msg.Status == 0) {
        let documentFilter = {
          documentSignedUrl: received_msg.FileServer
        }
        this.SIGNED_DOCUMENT({ id: this.documentSelect.id, ...documentFilter }).then(
          function () {
            this.$refs.avatarTab.addDocumentSuccess();
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': 'success'
            })
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.somethingWentWrong'),
              'styleType': 'danger'
            })
          }.bind(this)
        )
      } else {
        this.ON_SHOW_TOAST({
          'message': this.$t('common.somethingWentWrong'),
          'styleType': 'danger'
        })
      }
    },
    /**
     * Sign document file
     */
    exc_sign_file() {
      var prms = {};
      var metaArray = [{"Key":"token", "Value": this.mediaServerToken}];
      prms["FileUploadHandler"] = process.env.VUE_APP_SIGNED_FILE_ULR;
      prms["SessionId"] = "";
      prms["FileName"] = this.documentSelect.url;
      prms["MetaData"] = metaArray;
      var json_prms = JSON.stringify(prms);
      // eslint-disable-next-line no-undef
      vgca_sign_file(json_prms, this.SignFileCallBack);
    },
    isEmptyString: function (data) {
      return functionUtils.isEmptyString(data)
    },
    // Check null
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * On add event session success
     */
    onAddEventSessionSuccess: function () {
      this.$refs.avatarTab.addDocumentSuccess();
    },
    /**
     * On expand
     */
    onExpand: function () {
      this.isZoom = !this.isZoom
    },
    /**
     * Approve document
     */
    approveDocument: function() {
      let isUpdate = false
      this.$refs.reviewerModal.onShowModalConfirm(this.documentSelect.id, isUpdate, this.documentSelect.name, this.documentSelect.ownerId)
    },
    /**
     * Update document reviewer
     */
    updateDocumentReviewer: function () {
      let isUpdate = true
      this.$refs.reviewerModal.onShowModalConfirm(this.documentSelect.id, isUpdate, this.documentSelect.name)
    },
    /**
     * Show modal reject document
     */
    showModalRejectDocument: function() {
      this.$refs["modal-confirm-absence"].show();
    },
    /**
     * Reject document
     */
    rejectDocument: function() {
      let filter = {
        documentId: this.documentSelect.id,
        status: DocumentStatus.REJECTED,
        reasonReject: this.reasonReject,
      };
      this.REJECT_DOCUMENT_REVIEW(filter)
        .then(
          function() {
            this.reasonReject = null;
            this.$refs["modal-confirm-absence"].hide();
            this.$refs.avatarTab.addDocumentSuccess();
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
            this.sendNotificationReject()
          }.bind(this)
        )
        .catch(
          function() {
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    /**
     * On delete document
     */
    onShowModalDeleteDocument: function() {
      this.$refs.confirmModal.onShowModalConfirm(`${this.$t('document.titleConfirmDelete')} ${this.documentSelect.name}`)
    },
    /**
     * Add document in event success
     */
    addDocumentInEventSuccess: function(addDocumentType) {
      this.$refs.avatarTab.addDocumentSuccess(addDocumentType);
    },
    /**
     * Add new document in event session
     */
    onAddDocumentInEventSession: function(meetingResource) {
      this.addEventSessionDocument = true;
      this.addEventSession = false;
      this.isShowResourcesInEventSession = false;
      this.documentPersonalTabSelect = false
      this.showDocumentExchange = false
      if (meetingResource != null && meetingResource != undefined) {
        if (meetingResource == AddDocumentType.PERSONAL) {
          this.$refs.addDocumentInEventSession.addDocumentForPersonal()
        } else {
          this.$refs.addDocumentInEventSession.addDocumentForEventSessionAssign(meetingResource)
        }
      } else {
        this.$refs.addDocumentInEventSession.addDocumentForEventSessionCustom()
      }
    },
    /**
     * On update document
     */
    onUpdateDocument: function () {
      this.addEventSessionDocument = true;
      this.addEventSession = false;
      this.isShowResourcesInEventSession = false;
      this.documentPersonalTabSelect = false
      this.$refs.addDocumentInEventSession.updateDocumentForEventSession(this.documentSelect.id, this.documentSelect.name)
    },
    /**
     * Add new event session
     */
    onAddEventSession: function() {
      this.addEventSession = true;
      this.isShowResourcesInEventSession = false;
      this.addEventSessionDocument = false;
      this.documentPersonalTabSelect = false
      this.showDocumentExchange = false
    },
    /**
     * On submit note
     */
    onSubmitNote: function() {
      if (this.noteDescription == "" || this.noteDescription.length <= 0) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.note.invalidEnterNote"),
          styleType: "danger",
        });
        return;
      }
      let filter = {
        documentId: this.documentSelect.id,
        description: this.noteDescription,
      };
      this.CREATE_NOTE_IN_DOCUMENT(filter)
        .then(
          function() {
            this.noteDescription = "";
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
          }.bind(this)
        )
        .catch(
          function() {
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    /**
     * On select document
     */
    onSelectDocument: function(document) {
      this.isShowResourcesInEventSession = false;
      this.addEventSession = false;
      this.addEventSessionDocument = false;
      this.documentPersonalTabSelect = false
      this.documentSelect.id = document.id;
      this.documentSelect.name = document.name;
      this.documentSelect.url = document.url;
      this.documentSelect.urlPdfAnnotate = document.url && document.url.replace('app.avatarnext.com', 'meeting.avatarnext.com')
      this.documentSelect.ownerId = document.owner && document.owner.id;
      this.documentSelect.status = document.status;
      this.documentSelect.reviewer = document.reviewer;
      this.documentSelect.hasViewCurrentDocument = document.hasViewCurrentDocument
      this.documentSelect.currentDigitalSigner = document.currentDigitalSigner
    },
    /**
     * On open note
     */
    onOpenNote: function() {
      this.openNote = true;
      // setTimeout(() => {
      //   document.getElementById("noteMessage").focus();
      // }, 100);
    },
    /**
     * Format date time
     */
    formatDateTime: function(dateTime) {
      return dateUtils.formatDate(
        dateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
        "DD/MM/YYYY"
      );
    },
    /**
     * On view comment
     */
    onViewComment: function(document, index) {
      document.isSelected = !document.isSelected;
      for (let i = 0, size = this.documents.length; i < size; i++) {
        if (i != index) {
          this.documents[i].isSelected = false;
        }
      }
    },
    /**
     * On event selected
     */
    onEventSelected: function(eventSession) {
      let eventId = sessionStorage.getItem('event_id')
      this.isShowResourcesInEventSession = true;
      this.addEventSession = false;
      this.addEventSessionDocument = false;
      this.documentPersonalTabSelect = false
      this.showDocumentExchange = false
      this.eventSessionId = eventSession.id;
      this.eventSessionDescriptionSelected = eventSession.description;
      let filter = {
        params: {
          eventSessionId: eventSession.id,
          eventId: eventId
        },
      };
      this.GET_DOCUMENTS_IN_EVENT_SESSION(filter);
    },
    sendNotificationReject: function(){
      let data = {
        event_id: this.eventId,
        // message_title: this.event.name,
        // message_body: this.$t('notification.document.reject', {'0': this.documentSelect.name, '1': this.currentUser.lastName + " " +this.currentUser.firstName}),
        user_id: this.documentSelect.ownerId,
        action_code: TemplateCategoriesEnum.DOCUMENT_REJECTED,
        template_type: Enum.TemplateType.Notification,
        document_name: this.documentSelect.name,
        reject_by_user: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        notification_type_code: Enum.NotificationType.DocumentReject
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // let smsContent = this.$t('notification.document.reject', {'0': this.documentSelect.name, '1': this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname})

      let smsData = {
        event_id: this.eventId,
        // content: functionUtils.uniDecodeString(smsContent),
        user_id: this.documentSelect.ownerId,
        action_code: TemplateCategoriesEnum.DOCUMENT_REJECTED,
        template_type: Enum.TemplateType.SMS,
        document_name: this.documentSelect.name,
        reject_by_user: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let data = {
        action_code: TemplateCategoriesEnum.DOCUMENT_REJECTED,
        template_type: Enum.TemplateType.Email,
        event_id: this.eventId,
        document_name: this.documentSelect.name,
        reject_by_user: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        user_id: this.documentSelect.ownerId,
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    /**
     * Switch docment success
     */
    onSwitchDocumentSuccess: function () {
      // Reload tab personal
      this.$refs.avatarTab.addDocumentSuccess(AddDocumentType.PERSONAL);
      // Reload tab general
      this.$refs.avatarTab.addDocumentSuccess();
    },
    /**
     * Switch to edit pdf mode
     */
    onSwitchToEditPdfMode: function (editPdfMode) {
      if (editPdfMode) {
        this.editPdfMode = true
        this.isZoom = true
      } else {
        this.editPdfMode = false
        this.isZoom = false
      }
    },
    onShowDocumentUploaderModal: function(){
      this.$refs.documentUploaderModal.onShowModalConfirm(this.eventSessionId)
    },
    onDocumentExchangeSelectTab: function() {
      this.isShowResourcesInEventSession = false;
      this.addEventSession = false;
      this.addEventSessionDocument = false;
      this.documentPersonalTabSelect = false
      this.showDocumentExchange = true
    },
    onSelectDocumentExchangeData: function (data) {
      this.documentExchangeData = data
      this.isShowResourcesInEventSession = false;
      this.addEventSession = false;
      this.addEventSessionDocument = false;
      this.documentPersonalTabSelect = false
      this.showDocumentExchange = true
    },
    onZoomDocumentExchange: function (data) {
      this.isZoom = data
    },
    ...mapActions([
      "GET_DOCUMENTS_IN_EVENT_SESSION",
      "CREATE_NOTE_IN_DOCUMENT",
      "ON_SHOW_TOAST",
      "DELETE_DOCUMENT",
      "REJECT_DOCUMENT_REVIEW",
      "SIGNED_DOCUMENT",
      "GET_TOKEN_MEDIA_SERVER",
      "CREATE_DOCUMENT",
      "SEND_NOTIFICATION_IN_MEETING",
      "GET_EVENT_DETAIL",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ]),
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  color: #ffffff;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.expand {
  color: #F2404D
}
// For pdf annotate
/deep/.toolbar {
  position: unset !important;
}
/deep/#content-wrapper {
  position: unset;
  height: 90vh;
  overflow: auto;
}
/deep/.pdfViewer .page {
  width: 100% !important;
  height: 90vh;
}
.pdf_file .save-document-personal-btn {
  position: absolute; 
  right: 60px; 
  top: 3px; 
  cursor: pointer;
}
.pdf_file .close-edit-pdf-mode-btn {
  position: absolute; 
  right: 3px; 
  top: 3px; 
  cursor: pointer;
}
/deep/.hotspot-color div {
  z-index: 1;
}
/deep/.text-color div {
  z-index: 1;
}
/deep/.pen-color div {
  z-index: 1;
}
</style>
