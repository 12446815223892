<template>
  <div>
    <b-modal v-model="isShow" :title="$t('common.confirm')" centered id="modal-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mb-2" style="padding: 0 !important; margin-top: 0 !important;">
            <span>{{ $t("document.currentDocumentUploader") }}:</span>
            <div style="display: block;" v-if="documentUploadersName.length > 0">
              <div
                v-for="(documentUploader, index) in documentUploadersName"
                :key="documentUploader + index"
                style="display: inline-block;"
              >
                <div
                  class="mr-1 mt-1"
                  style="padding: 0px 4px 4px 4px; background-color: #41b883; color: #fff; border-radius: 5px;"
                >
                  <span class="meeting-font-medium">{{ documentUploader.full_name }}</span>
                  <span
                    @click="removeCurrentDocumentUploader(documentUploader)"
                    class="meeting-font-small"
                    style="background-color: #369a6e; padding: 2px 5px 2px 5px; cursor: pointer; border-radius: 5px;"
                  >X</span>
                </div>
              </div>
            </div>
          </div>
          <p>{{ $t("document.documentUploader") }}</p>
          <div class="col-md-12" style="padding: 0 !important;">
            <VueMutiselect
              ref="approverMutiSelect"
              :optionData="documentUploaderList"
              :customLabel="customLabel"
              :labelText="labelTextValue"
              :trackByText="trackByTextValue"
              :placeHolder="$t('mission.selectPerson')"
              :close-on-select="false"
              @select="getAssigner"
            ></VueMutiselect>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >{{ $t("common.accept") }}</b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >{{ $t("common.close") }}</b-button>
        </div>
      </template>
    </b-modal>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from "utils/functionUtils";
import VueMutiselect from "Components/VueMutiselect";
import Enum from "enum/enum"
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    VueMutiselect,
  },
  data: () => ({
    isShow: false,
    documentUploadersName: [],
    sessionId: null,
    documentUploaderSelected: [],
    documentUploaderList: [],
    eventId: null,
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    documentUploaderVueMutiselect: [],
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR"]),
  },
  methods: {
    removeCurrentDocumentUploader: function (uploader) {
      for (let i = 0, size = this.documentUploadersName.length; i < size; i++) {
        if (uploader.id == this.documentUploadersName[i].id) {
          this.documentUploadersName.splice(i, 1);
          this.documentUploaderSelected = this.documentUploaderSelected.filter(
            (item) => {
              return item.id != uploader.id;
            }
          );
          break;
        }
      }
      this.documentUploaderList.push(uploader);
    },
    getUsersInEvent: function () {
      let filter = {
        params: {
          sessionId: this.sessionId,
        },
      };
      this.GET_USER_IN_EVENT_SESSION(filter).then(
        function (res) {
          let data = res.data;
          this.documentUploaderSelected = [];
          this.documentUploadersName = [];
          for (let i = 0, size = data.length; i < size; i++) {
            if (!functionUtils.isNull(data[i].user)) {
              this.documentUploaderSelected.push({
                id: data[i].user.id,
                is_group: data[i].is_group,
              });
              // Get full name
              let fullname =
                data[i].user.last_name + " " + data[i].user.first_name;
              // Get id
              let id = data[i].user.id;
              let url_avatar =
                data[i].user.profile && data[i].user.profile.url_avatar;
              let is_group = data[i].is_group;
              this.documentUploadersName.push({
                id: id,
                full_name: fullname,
                url_avatar: url_avatar,
                is_group: is_group,
              });
            }
          }
          //
          let attendesSpeakersFilter = {
            params: {
              event: this.eventId,
              bandGroup: true,
            },
          };
          this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter).then(
            function (res) {
              let data = res.data;
              this.documentUploaderList = [];
              if (!functionUtils.isNull(data)) {
                data.forEach((element) => {
                  let full_name = element.last_name + " " + element.first_name;
                  let item = {
                    id: element.id,
                    full_name: full_name,
                    url_avatar:
                      (element.profile && element.profile.url_avatar != null) ||
                      element.profile.url_avatar != ""
                        ? element.profile.url_avatar
                        : null,
                    is_group: element.is_group,
                  };
                  let isExist = false;
                  for (
                    let i = 0, size = this.documentUploadersName.length;
                    i < size;
                    i++
                  ) {
                    if (this.documentUploadersName[i].id == item.id) {
                      isExist = true;
                    }
                  }
                  if (!isExist) {
                    this.documentUploaderList.push(item);
                  }
                });
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },
    onShowModalConfirm: function (sessionId) {
      this.isShow = true;
      this.sessionId = sessionId;
      this.getUsersInEvent();
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssigner(data) {
      this.documentUploaderVueMutiselect = [];
      data.forEach((item) => {
        this.documentUploaderVueMutiselect.push({
          id: item.id,
          is_group: item.is_group,
        });
      });
    },
    onConfirm: function () {
      let data = {
        sessionId: this.sessionId,
        documentUploader: this.documentUploaderSelected.concat(
          this.documentUploaderVueMutiselect
        ),
      };
      this.UPDATE_USER_IN_EVENT_SESSION(data)
        .then(
          function () {
            this.isShow = false;
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
            this.sendNotification()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isShow = false;
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    sendNotification(){
      let userList = []
      let groupList = []

      this.documentUploaderVueMutiselect.forEach(data=>{
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let notificationData = {
        event_id: this.eventId,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.UPDATE_USER_UPLOAD_DOCUMENT,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.DocumentUploader,
        sessionId: this.sessionId
      }
      if(this.documentUploaderVueMutiselect.length > 0){
        this.SEND_NOTIFICATION_IN_MEETING(notificationData)
      }

      let smsData = {
        event_id: this.eventId,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.UPDATE_USER_UPLOAD_DOCUMENT,
        template_type: Enum.TemplateType.SMS,
        sessionId: this.sessionId
      }
      if(this.documentUploaderVueMutiselect.length > 0){
        this.SEND_SMS_IN_MEETING(smsData)
      }
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_USER_IN_EVENT_SESSION",
      "GET_USER_IN_EVENT_LIST",
      "UPDATE_USER_IN_EVENT_SESSION",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING"
    ]),
  },
  created() {
    this.eventId = sessionStorage.getItem("event_id");
  },
};
</script>